import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import { LaptopIcon } from '../../../../Icons/laptop-icon';
import { HeartBeatIcon } from '../../../../Icons/heart-beat-icon';
import { HandCoinsIcon } from '../../../../Icons/hand-coins-icon';
import { PiggyBankIcon } from '../../../../Icons/piggy-bank-icon';
import { UsersThreeIcon } from '../../../../Icons/users-three-icon';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import Carousel from '../../../../Components/Carousel';
import { IslandIcon } from '../../../../Icons/island-icon';
import { GiftIcon } from '../../../../Icons/gift-icon';
import { EarthIcon } from '../../../../Icons/earth-icon';
import { BuildingOfficeIcon } from '../../../../Icons/building-office-icon';
import { BrainIcon } from '../../../../Icons/brain-icon';
import { DevicesIcon } from '../../../../Icons/devices-icon';
import { theme } from '../../../../Style/theme';
import { BriefCaseIcon } from '../../../../Icons/brief-case-icon';

const LifeAtSpreeAIPageSectionBenefits = () => {
    const isMobile = useWindowDimensions().windowWidth <= 1000;

    const BENEFITS = [
        {
            icon: HeartBeatIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit1Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit1Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: HandCoinsIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit2Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit2Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: PiggyBankIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit3Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit3Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: LaptopIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit4Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit4Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: BriefCaseIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit5Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit5Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: UsersThreeIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit6Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit6Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
    ];

    const BENEFITS_2 = [
        {
            icon: IslandIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit7Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit7Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: GiftIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit8Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit8Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: EarthIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit9Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit9Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: DevicesIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit10Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit10Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: BuildingOfficeIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit11Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit11Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: BrainIcon,
            title: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit12Title,
            paragraph: Copydeck.lifeAtSpreeAipageBenefitsSectionSectionBenefit12Description,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
    ];

    return (
        <div className="LifeAtSpreeAIPageSectionBenefits">
            <div className="content">
                <WrapperSection.Title className="title capitalize" htmlTag="h1">
                    {Copydeck.lifeAtSpreeAipageBenefitsSectionOurBenefits}
                </WrapperSection.Title>

                <div className="text-body sub-paragraph">
                    {Copydeck.lifeAtSpreeAipageBenefitsSectionOurBenefitsSubtitle}
                </div>

                {isMobile ? (
                    <div className="mobile-carousel-container">
                        <Carousel
                            centerMode={false}
                            infiniteScroll={false}
                            slidesToShow={1}
                            carouselElements={[
                                <div className="benefits">
                                    {BENEFITS.map((benefit, index) => {
                                        const IconComponent = benefit.icon;

                                        return (
                                            <li key={index} className="benefit">
                                                <div
                                                    className="benefit-wrapper-icon"
                                                    style={{
                                                        backgroundColor: theme['white'],
                                                    }}
                                                >
                                                    <IconComponent
                                                        fill={benefit.color}
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>

                                                <div className="benefit-info">
                                                    <div className="benefit-title text-body-primary-font-bold text-element-black-secondary-alt">
                                                        {benefit.title}
                                                    </div>
                                                    <div className="benefit-description text-body">
                                                        {benefit.paragraph}
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </div>,
                                <div className="benefits">
                                    {BENEFITS_2.map((benefit, index) => {
                                        const IconComponent = benefit.icon;

                                        return (
                                            <li key={index} className="benefit">
                                                <div
                                                    className="benefit-wrapper-icon"
                                                    style={{
                                                        backgroundColor: benefit.backgroundColor,
                                                    }}
                                                >
                                                    <IconComponent
                                                        fill={benefit.color}
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>

                                                <div className="benefit-info">
                                                    <div className="benefit-title text-body-primary-font-bold text-element-black-secondary-alt">
                                                        {benefit.title}
                                                    </div>
                                                    <div className="benefit-description text-body">
                                                        {benefit.paragraph}
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </div>,
                            ]}
                        />
                    </div>
                ) : (
                    <ul className="benefits">
                        {[...BENEFITS, ...BENEFITS_2].map((benefit, index) => {
                            const IconComponent = benefit.icon;

                            return (
                                <li key={index} className="benefit">
                                    <div
                                        className="benefit-wrapper-icon"
                                        style={{
                                            backgroundColor: benefit.backgroundColor,
                                        }}
                                    >
                                        <IconComponent
                                            fill={benefit.color}
                                            width={40}
                                            height={40}
                                        />
                                    </div>

                                    <div className="benefit-info">
                                        <div className="benefit-title text-body-primary-font-bold text-element-black-secondary-alt">
                                            {benefit.title}
                                        </div>
                                        <div className="benefit-description text-body">
                                            {benefit.paragraph}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default LifeAtSpreeAIPageSectionBenefits;
